import React from "react";
import { Notfound } from "../../components/Loaders";


const NotFound = () => {

  return (
    <Notfound />
  );
};

export default NotFound;
