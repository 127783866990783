import { useLottie } from "lottie-react";
import hackintosh from "../assets/hackintosh.json";
import Logo from "../assets/nimbble-white.svg";

const Loading = () => {
  return (
      <div className="flex items-center justify-center min-h-screen">
        <div className=" w-96 text-xl text-slate-700 text-center p-8">
          <h1 className="animate-pulse font-semibold text-custompurple">Loading...</h1>
        </div>
      </div>
  );
};

const Notfound = () => {
  const options = {
    animationData: hackintosh,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options);

  return (
    <div className="h-screen bg-custompink transition-all  duration-75 delay75 flex items-center justify-center">
      <div className="text-center">
        <img src={Logo} className=" w-1/2 mx-auto mb-2.5" alt="" />
        <h1 className=" text-8xl text-white font-medium">404</h1>
        <div className="h-56 w-56 mt-7 mb-5 mx-auto">{View}</div>
        <div className="w-96 my-12 mx-auto">
          <h1 className="text-xl text-white font-medium">
            So that one kinda sneaked up on you. There, there! Keep calm and
          </h1>
        </div>
        <div className="">
          <a href="/" className="rounded-sm bg-custompurple hover:shadow-md text-white text-xl font-semibold py-2 px-10">
            GO HERE
          </a>
        </div>
      </div>
    </div>
  );
};

export { Loading, Notfound };
