import React from 'react';
import Navbar from "../../components/Navbar";
import {ArrowLongRightIcon} from "@heroicons/react/24/outline";
import Logo from '../../assets/nimbble.svg'


const Home = () => {
    return (
        <div className="h-screen flex justify-center items-center bg-custompurple scrollbar-hide p-5">
                    <div className=" lg:w-1/4 bg-white shadow-xl items-center p-10 rounded-xl">
                        <div className="mb-8">
                            <img
                                className="h-6 w-auto"
                                src={Logo}
                                alt="Nimbble"
                            />
                        </div>
                        <p className="text-md font-bold text-customdeeppurple uppercase">Get there. Together</p>
                        <h1 className="mt-5 leading-tight text-5xl md:text-7xl text-customdeeppurple font-black break-normal">
                            We've <span
                            className="text-custompurple underline-offset-2 underline">Moved</span>
                        </h1>
                        <p className="mt-5 text-customdeeppurple font-bold break-normal">
                            We've moved and officially launched a new shiny version of your booking app on the 18 Feb 2024. This domain is now closed.
                        </p>
                        <div className='md:mt-5 mt-5 bg-wh'>
                            <a href={'https://nimbble.co.za'}
                               className="shadow flex items-center justify-between w-60 px-5 py-4 text-sm tracking-wide font-bold text-white capitalize transition-colors duration-300 transform bg-custompink rounded hover:bg-white hover:text-custompurple focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                                <span>Visit our new home</span>
                                <ArrowLongRightIcon className="w-5 h-5"/>
                            </a>
                        </div>
                    </div>
        </div>
    )
}

export default Home
