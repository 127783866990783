import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Protected Components

// Outer Components
import Home from "./views/Home";

// Catchall Component
import NotFound from "./views/NotFound";


function App() {
    return (
                <Router>
                    <Routes>
                        <Route exact path={'/'} element={<Home />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </Router>
    );
}

export default App;
